<script>
import { get, sync } from 'vuex-pathify'
import debounce from 'lodash/debounce'

function getScrollPercent() {
	const h = document.documentElement
	const b = document.body
	const st = 'scrollTop'
	const sh = 'scrollHeight'
	return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
}

export default {
	props: {
		popupConfig: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			show: false,
			timeout: null,
			hasShowed: false,
			baseKey: 'smart-popup',
			scrollPercentHandler: null,
			name: null,
			email: null,
			valid: false,
			rules: {
				required: (value) => !!value || 'Requerido.',
				email: (value) => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'e-mail no valido.'
				},
			},
		}
	},
	watch: {
		'$route.fullPath'() {
			if (this.show) this.show = false
			if (this.popupConfig?.showAfterSeconds && this.isAllowedToShow) {
				this.clearExitIntent()
				this.showPopupUsingTimer()
			}
		},
	},
	mounted() {
		const setupFunctions = [
			{ property: 'showAfterSeconds', method: this.showPopupUsingTimer },
			{ property: 'exitIntent', method: this.setupExitIntent },
			{ property: 'showAfterScrollPercent', method: this.setupScrollPercent },
		]
		setupFunctions.forEach(({ property, method }) => {
			if (this.popupConfig?.[property]) method()
		})
	},
	beforeDestroy() {
		const clearFunctions = [
			{ property: 'showAfterSeconds', method: this.clearTimeout },
			{ property: 'exitIntent', method: this.clearExitIntent },
			{ property: 'showAfterScrollPercent', method: this.clearAfterScrollPercent },
		]
		clearFunctions.forEach(({ property, method }) => {
			if (this.popupConfig?.[property]) method()
		})
	},
	methods: {
		setupScrollPercent() {
			this.scrollPercentHandler = debounce(() => {
				const percent = getScrollPercent()
				const configScrollPercent = parseInt(this.popupConfig.scrollPercent)
				if (configScrollPercent > 0 && percent >= configScrollPercent) {
					this.showPopup()
				}
			}, 250)

			window.addEventListener('scroll', this.scrollPercentHandler)
		},
		clearAfterScrollPercent() {
			window.removeEventListener('scroll', this.scrollPercentHandler)
		},
		clearExitIntent() {
			document.documentElement.removeEventListener('mouseleave', this.showPopup)
		},
		setupExitIntent() {
			document.documentElement.addEventListener('mouseleave', this.showPopup)
		},
		showPopupUsingTimer() {
			const ms = parseFloat(this.popupConfig?.showAfterSecondsAmount) * 1000
			this.timeout = setTimeout(() => {
				this.showPopup()
			}, ms)
		},
		clearTimeout() {
			window.clearTimeout(this.timeout)
		},
		storedFrequency() {
			return parseInt(this.$window?.localStorage.getItem(this.storageKey)) || 0
		},
		incrementFrequency() {
			const f = this.storedFrequency() + 1
			this.$window?.localStorage.setItem(this.storageKey, f)
		},
		hasFrequencyLeft() {
			return this.popupConfig?.frequency > this.storedFrequency()
		},
		showPopup() {
			if (!this.isAllowedToShow) {
				this.clearRedirectLocks()
				return
			}

			this.incrementFrequency()
			this.show = true
			this.hasShowed = true
		},
		send() {
			if (!this.valid) return
		},
		ctaClick() {
			this.show = false
			if (this.$b.m && this.hasLocalRedirect) this.setRedirectLock()
		},
		setRedirectLock() {
			this.$window?.localStorage.setItem(`${this.storageKey}-lock`, 1)
		},
		clearRedirectLocks() {
			this.$window?.localStorage.removeItem(`${this.storageKey}-lock`)
		},
		hasRedirectLock() {
			return this.$window?.localStorage.getItem(`${this.storageKey}-lock`)
		},
	},
	computed: {
		productsImage() {
			if (this.popupConfig.position == 'smart-popup-center') return
			if (this.$b.d) {
				if (this.popupConfig.limit == 3) return this.popupConfig.productsSideImage
				return this.popupConfig.productSideImageLarge
			} else return this.popupConfig.productsMobileImage
		},
		hasLocalRedirect() {
			return this.popupConfig.ctaUrl.indexOf('/') === 0
		},
		comesFromLocalRedirect() {
			return document.referrer.includes(window.location.origin) && this.hasRedirectLock()
		},
		user: get('shop/user'),
		isAllowedToShow() {
			return !this.show && !this.hasShowed && this.hasFrequencyLeft() && !this.comesFromLocalRedirect
		},
		storageKey() {
			return `${this.baseKey}-${this.popupConfig.id}`
		},
		imageStyle() {
			const img = this.$b({ m: this.popupConfig.bgImageMobile, d: this.popupConfig.bgImage })
			return {
				'background-image': `url(${this.$media(img).src})`,
				'background-position': 'center center',
				'background-size': 'cover',
			}
		},
	},
}
</script>

<template>
	<v-dialog
		v-model="show"
		:max-width="
			popupConfig.position == 'smart-popup-center' ? '700px' : !productsImage ? '815px' : '1135px'
		"
		:width="popupConfig.bgFullImage ? 'auto' : '100%'"
		content-class="mx-0"
	>
		<div :class="storageKey" class="base" style="position: relative" v-if="!popupConfig.useBgFullImage">
			<Button class="close" :color="$vars.secondary" icon @click="show = false">
				<v-icon>mdi-close</v-icon>
			</Button>
			<v-container>
				<v-row v-if="popupConfig.position == 'smart-popup-center'">
					<v-col cols="12" sm="4" class="px-6 py-12" :style="imageStyle"></v-col>
					<v-col cols="12" sm="8" :class="$b.smAndDown ? 'py-5' : 'px-9 py-12'">
						<div>
							<div class="pa-3" v-if="popupConfig.insideImage">
								<Media width="100%" :src="popupConfig.insideImage" />
							</div>
							<div class="text-center py-3">
								<h1 class="font-5 header">{{ popupConfig.title }}</h1>
								<p>{{ popupConfig.text }}</p>
							</div>
							<SmartPopup-Form
								@ctaClick="ctaClick"
								v-if="popupConfig.enableCta"
								:popupConfig="popupConfig"
							/>
						</div>
					</v-col>
				</v-row>
				<v-row v-else class="pa-4" :class="{ 'flex-row-reverse': popupConfig.reverseImage }">
					<v-col v-if="productsImage" cols="12" md="3" class="d-flex justify-center">
						<SafeLink :to="popupConfig.imageUrl">
							<Media :src="productsImage" />
						</SafeLink>
					</v-col>
					<v-col cols="12" :md="productsImage ? 9 : 12">
						<v-row v-for="(products, n) in popupConfig.productGroups" :key="n">
							<v-col cols="12" sm="4" v-for="product in products" :key="product.id">
								<ProductCard
									:product="product"
									:square="$b.d && popupConfig.productGroups.length > 1"
									:vertical-card="$b.d"
								/>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<div v-else class="base" style="position: relative">
			<Button class="close" style="right: 0" :color="$vars.secondary" icon @click="show = false">
				<v-icon>mdi-close</v-icon>
			</Button>
			<SafeLink :to="popupConfig.ctaUrl">
				<Media
					:class="storageKey"
					:src="$b({ d: popupConfig.bgFullImage, m: popupConfig.bgFullImageMobile })"
					@click="ctaClick"
				>
				</Media>
			</SafeLink>
		</div>
	</v-dialog>
</template>

<style lang="scss" scoped>
::v-deep .v-label,
.v-input ::v-deep input {
	color: var(--primarytext);
}

.close {
	position: absolute;
	top: 5px;
	right: 5px;
	opacity: 1;
	z-index: 999;
	text-shadow: none;
	font-size: 24px;
}
</style>

